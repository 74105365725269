<template>
  <div class="popup-successful-add-object">
    <div class="popup-successful-add-object__wrapper">
      <Column class="grid-6"
              align="center"
              justify="center">
        <Row>
          <Icon class="popup-successful-add-object__icon"
                xlink="successfulObject"
                viewport="0 0 105 96"/>
        </Row>
        <p class="popup-successful-add-object__title">
          Благодарим за участие<br/></p>
        <p class="popup-successful-add-object__description">После модерации ваша
          экскурсия<br/> будет опубликованна на портале.</p>
        <div>
          <Button v-on:click.native="onMain"
                  class="popup-successful-add-object__button"
                  color="transparent">на главную
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupSuccessfulAddExcursion',
  methods: {
    onMain() {
      this.$store.commit('CLOSE_POPUP');
      if (this.$route.path !== '/') this.$router.push('/');
    },
  },
};
</script>

<style lang="scss">
  @import "./../../assets/style/utilits/index";

  .popup-successful-add-object {
    @include size(100%, 100%);
    overflow: auto;

    &__wrapper {
      @include size(100%);
      min-height: 100vh;
      @media (min-width: 1110px) {
        /*padding: 80px 0 80px 95px;*/
      }
      @media (min-width: 1px) and (max-width: 1109px) {
        padding: 80px 14px;
      }
      background-color: $green;
      background-image: url($assets + "svg/backgroundSuccessfulObject.svg");

      & > .column {
        margin: 0 auto;
      }
    }

    &__icon {
      margin: 200px 0 100px;
      fill: #7EF600;
    }

    &__title {
      margin: 0 0 20px;
      @include font(30px, bold, 1.33);
      color: $white;
      text-align: center;
    }

    &__description {
      margin: 0 0 20px;
      @include font(16px, normal, 1.5);
      color: $white;
      text-align: center;
    }
  }
</style>
